.suggestions-dropdown {
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 4;
  width: 100%;
  border-radius: 0;
  background-color: white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.options {
  font-size: 17px;
  line-height: 23px;
  color: #202020;
  cursor: pointer;
  padding: 8px 5px;
  text-align: left;
}

.sv-smart-search-container {
  /* Search Box */
  background-color: #F2F2F2;
  height: 56px;
  width: 100%;
  max-width: 440px;
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  color: #202020;

  .sv-smart-search {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    align-items: center;

    .sv-search-input {
      background-color: #F2F2F2;
      border: none;
      width: 0; /* Initially hidden */
      opacity: 0;
      transition: width 0.3s ease, opacity 0.3s ease; /* Add a transition effect */
    }
  }

  .sv-find-storage {
    align-self: center;
    // opacity: 1;
    width: 100%;
    transition: opacity 0.3s ease; /* Add a transition effect */
  }
  .sv-search-icon {
    margin-left:0;
  }

  /* Add hover styles */
  &:hover,
  &:focus-within {
    .sv-smart-search {
      .sv-search-input {
        width: 400px; /* Expand width on hover */
        opacity: 1; /* Fade in on hover */
        transition: width 0.3s ease, opacity 0.3s ease; 
      }
    }
    .sv-find-storage {
      opacity: 0;
      width: 0;
      transition: width 0.3s ease,
    }
  }
}

@media (max-width: 576px) {
  .sv-smart-search-container {
    height: 40px;
  }
}

@media (max-width: 900px) {
  .sv-smart-search-container {
    &:hover,
  &:focus-within {
    .sv-smart-search {
      .sv-search-input {
        width: 200px; 
      }
    }
  }
  }
  }

  @media (max-width: 700px) {
  .sv-smart-search-container {
    &:hover,
    &:focus-within {
      .sv-smart-search {
        .sv-search-input {
          display: none
        }
      }
      .sv-find-storage {
        opacity: 1;
        width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .sv-smart-search-container {
    padding: 8px 6px;
    .sv-find-storage {
      display: none;
    }
    .sv-search-input {
      display: none
    }
  }
}

@media (min-width: 900px) {
  .scroll {
    // expand th search box in navbar to full width
    .sv-smart-search {
      .sv-search-input {
        width: 400px; /* Expand width on hover */
        opacity: 1; /* Fade in on hover */
        transition: width 0.3s ease, opacity 0.3s ease; 
      }
    }
    .sv-find-storage {
      opacity: 0;
      width: 0;
      transition: width 0.3s ease,
    }
  }
}

@media (min-width: 700px) {
  @media(max-width: 900px) {
    .scroll {
      // expand th search box in navbar to full width
      .sv-smart-search {
        .sv-search-input {
          width: 200px; /* Expand width on hover */
          opacity: 1; /* Fade in on hover */
          transition: width 0.3s ease, opacity 0.3s ease; 
        }
      }
      .sv-find-storage {
        opacity: 0;
        width: 0;
        transition: width 0.3s ease,
      }
    }
  }
}